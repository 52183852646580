import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import LazyLoad from 'react-lazy-load';

const Map = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          googleMapsEmbed
        }
      }
    }
  `);
  return (
    <div className="w-full mx-auto mt-12">
      <LazyLoad height={480}>
        <iframe
          src={data.site.siteMetadata.googleMapsEmbed}
          frameBorder="0"
          title="map"
          height="480"
          className="w-full overflow-hidden border"
        />
      </LazyLoad>
    </div>
  );
};

export default Map;
