import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import * as React from 'react';

const ContactHeroImage = () => {
  const data = useStaticQuery(graphql`
    {
      mobileImage: file(name: { eq: "contact-hero-mobile" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      desktopImage: file(name: { eq: "contact-hero" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90, traceSVG: { color: "#152735" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const imageSources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Image
      fluid={imageSources}
      loading="eager"
      imgStyle={{ objectPosition: `top` }}
      style={{
        minHeight: `40rem`,
        maxHeight: `50rem`,
      }}
      alt="Jamie Carter Frontline removalist General Manager making a call"
    />
  );
};

export default ContactHeroImage;
