import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

const ContactBanner = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phoneFormatted
          address
        }
      }
    }
  `);
  return (
    <dl className="px-6 py-6 -mb-16 text-3xl leading-tight tracking-wide text-center text-white uppercase md:flex md:flex-wrap md:justify-center font-display bg-brand-blue">
      <dt className="mt-2 whitespace-nowrap first:mt-0 md:mt-0 md:inline-block text-brand-orange">
        Phone:&nbsp;
      </dt>
      <dd className="md:inline-block">
        <a
          href={`tel:${data.site.siteMetadata.phoneFormatted.replace(
            /\s+/g,
            ''
          )}`}
        >
          {data.site.siteMetadata.phoneFormatted}
        </a>
      </dd>
      <span className="hidden mx-6 md:inline-block">|</span>
      <dt className="mt-2 whitespace-nowrap first:mt-0 md:mt-0 md:inline-block text-brand-orange">
        Head Office:&nbsp;
      </dt>
      <dd className="md:inline-block">{data.site.siteMetadata.address}</dd>
    </dl>
  );
};

export default ContactBanner;
